import Vue from 'vue'
import axios from 'axios'
import router from '../router';
import store from '../store';


const date = new Date();
axios.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  // 获取token, 并添加到 headers 请求头中
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.lang = store.state.lang;
  config.headers.mode = store.state.mode;
  config.headers.timezone = date.getTimezoneOffset();
  config.url = Vue.prototype.$host + config.url;
  if (!config.data) config.data = {};

  return config;
});

axios.interceptors.response.use(res => {
  if (res.data) {
    return Promise.resolve(JSON.parse(Vue.prototype.$t(JSON.stringify(res.data))));
  }
  return Promise.resolve();
}, err => {
  // 没有登录或令牌过期
  console.log(err.response.config.url)
  if (err.response.status === 401) {
    store.commit("setUser", null);
    // 跳转的登录页
    Vue.prototype.$message("登陆已过期，请重新登陆!");
    router.push('/login');

  } else {
    Vue.prototype.$message(Vue.prototype.$t(err.response.data.message))
  }
  return Promise.reject(err);
});

export default axios;