import Vue from 'vue'
let word = null
let isSelecting = false
let index = 0;
let startIndex = -1
let endIndex = -1

setTimeout(() => {
  Vue.prototype.$bus.$on('word-refresh', () => {
    index = 0
  })
}, 500);

export default {

  inserted(el) {
    el.setAttribute('index', index++)
    el.className += ' pointer'
    Vue.prototype.$bus.$on("word-reset", () => {
      el.classList.remove("hover")
    })

    Vue.prototype.$bus.$on("word-hover", () => {
      let elIndex = Number(el.getAttribute('index'))
      el.classList.remove("hover")
      if (elIndex >= startIndex && elIndex <= endIndex) {
        el.className += ' hover'
      }
    })

    el.onmousedown = () => {
      isSelecting = true;
      startIndex = Number(el.getAttribute('index'))
      endIndex = startIndex
      el.className += ' hover'
    }
    el.onmousemove = () => {
      if (isSelecting) {
        let lastIndex = Number(el.getAttribute('index'))
        if (lastIndex != endIndex) {
          endIndex = lastIndex
          Vue.prototype.$bus.$emit("word-hover")
        }
        el.className += ' hover'
      }
    }
    el.onmouseup = () => {
      isSelecting = false;
      console.log(startIndex, endIndex)
      if (startIndex != -1 && endIndex != -1) {
        let target = null
        let selectedWords = [];
        for (let i = startIndex; i <= endIndex; i++) {
          target = document.querySelector(`[index="${i}"`)
          word = (target.getAttribute("word") || target.innerText).trim().replace(/[_.]/g, '')
          if (word.replace(/\W/g, '')) {
            selectedWords.push(word)
          }
        }
        Vue.prototype.$bus.$emit("word", selectedWords.join(" "))
      }
      startIndex = -1
      endIndex = -1
      setTimeout(() => {
        Vue.prototype.$bus.$emit("word-reset")
      }, 800);
    }
  },
}