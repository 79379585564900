import Vue from 'vue'
import App from './App.vue'

import store from './store';

import bus from './common/bus.js'
Vue.prototype.$bus = bus;

if (window.location.hostname == "tw.ptefighter.com") {
  store.commit("setIsTw", true)
}


import word from "./components/word"
Vue.directive('word',word)

import convert from './language-tw-loader'

import router from './router';

Vue.prototype.$host = "";
if (process.env.NODE_ENV === "development") {
  Vue.prototype.$host = "https://www.ptefighter.com";
}

import ElementUI from 'element-ui';
import './assets/element-variables.scss'

import './assets/common.css'
// import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import request from './request';
Vue.prototype.$http = request


import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)


Vue.prototype.$domain = location.protocol + "//" + location.host

Vue.prototype.$vip_alert = (title, content) => {
  Vue.prototype.$confirm(content ? content : Vue.prototype.$t(`${title}需要VIP用户才能使用,是否开通VIP会员?`), Vue.prototype.$t('提示'), {
    confirmButtonText: Vue.prototype.$t('升级'),
    cancelButtonText: Vue.prototype.$t('取消'),
    type: 'warning'
  }).then(() => {
    router.push("/main/vip")
  }).catch(() => {

  });
}


Vue.prototype.$t = (text, lang) => {
  if (lang == 'zh') {
    return convert(text, 'zh')
  } else if (store.state.lang == 'tw' || lang == 'tw') {
    return convert(text)
  }
  return text;
}


Vue.prototype.$url = (data, key) => {
  let newKey = key
  if (store.state.lang) {
    newKey += "_" + store.state.lang
    if (!data[newKey]) newKey = key;
  }

  return convert(data[newKey], 'zh');
}

import moment from 'moment'
Vue.prototype.$moment = moment



// // 路由守卫
router.beforeEach((to, from, next) => {
  // 判断要进入的路由是否需要认证
  bus.$emit("toggle_menu");
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // } else {
  //   document.title = "海澳英语";
  // }
  document.title = Vue.prototype.$t('海澳英语-PTE/CCL培训')
  if (to.meta.auth) {
    if (store.state.user) {
      next();
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    next();
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
